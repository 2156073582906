<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .neues </v-flex>
    </v-layout>

    <!-- news -->

    <v-layout column>
      <v-flex v-for="(newsEntry, i) in news" :key="i" :id="'id' + i" xs12 my-5>
        <v-card>
          <v-img
            v-if="newsEntry.titleImage"
            class="odd"
            :src="'/img/neues/' + newsEntry.titleImage"
          ></v-img>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >{{ newsEntry.title }}</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div v-html="newsEntry.text"></div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: ["news"],
  data() {
    return {};
  },
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      this.scrollToElement(this.$route.params.id);
    }
  },
};
</script>

<style scoped></style>
