<template>
  <v-container>
    <!-- projects -->

    <v-layout column>
      <v-flex
        xs12
        :id="computedProject.title.replace(/\s/g, '').split('.').join('')"
        my-5
      >
        <v-card class="pb-5">
          <v-img
            class="odd"
            :src="'/img/projekte/' + computedProject.titleImage"
          ></v-img>
          <v-card-title
            :class="{
              breakall: true,
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >{{ computedProject.title }}</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div
              v-if="computedProject.contact"
              class="font-weight-light font-italic"
            >
              Ansprechpartner*in:
              <a @click="$router.push('/ueber-uns/team')">{{
                computedProject.contact.name
              }}</a>
              / {{ computedProject.contact.email }}
            </div>
            <br />
            <p v-html="computedProject.text"></p>
            <br />
            <v-carousel
              class="mt-3 mb-5"
              v-if="computedProject.images && computedProject.images.length > 0"
            >
              <v-carousel-item
                v-for="(item, i) in computedProject.images"
                :key="i"
                :src="'/img/projekte/' + item"
              ></v-carousel-item>
            </v-carousel>
            <v-carousel
              class="mb-5"
              hide-delimiters
              id="carousel"
              :cycle="false"
              :style="'height: ' + videoHeight + 'px !important'"
              v-if="computedProject.videos && computedProject.videos.length > 0"
            >
              <v-carousel-item
                v-for="(item, i) in computedProject.videos"
                :key="i"
              >
                <div class="responsive-video">
                  <iframe
                    width="100%"
                    :src="
                      'https://www.youtube-nocookie.com/embed/' +
                      item.link +
                      '?rel=0&amp;showinfo=0'
                    "
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
              </v-carousel-item>
            </v-carousel>
            <v-container
              v-if="
                computedProject.partners && computedProject.partners.length > 0
              "
              grid-list-lg
              pa-0
              ma-0
            >
              <div class="subheading mb-3">Projekt-Partner:</div>
              <v-layout row wrap>
                <v-flex
                  v-for="(partner, i) in computedProject.partners"
                  :key="i"
                  xs6
                  sm3
                  md3
                  lg2
                >
                  <a target="_blank" :href="partner.link">
                    <v-card flat tile style="padding: 15px" color="white">
                      <div
                        class="partner-container"
                        :style="
                          'background-image:url(/img/partner/' + partner.logo
                        "
                      ></div>
                    </v-card>
                  </a>
                </v-flex>
              </v-layout>
            </v-container>
            <template v-if="computedProject.feedback === 'true'">
              <br /><br />
              <v-btn
                class="mx-0"
                outline
                v-if="visibility === false"
                @click="setVisibility()"
              >
                Feedback abgeben
              </v-btn>
              <v-form v-if="visibility === true && !isSent" v-model="valid">
                <v-textarea
                  v-model="message"
                  box
                  label="Dein Feedback"
                  :rules="[
                    (v) => !!v || 'Wolltest du uns nicht etwas Mitteilen?',
                  ]"
                  hint="Falls du eine Antwort auf dein Feedback wünschst, denke bitte auch an deine Kontaktdaten."
                >
                </v-textarea>
                <v-checkbox
                  v-model="accept"
                  color="primary"
                  label="Ich habe die Datenschutz-Erklärung gelesen und akzeptiere diese."
                  :rules="[
                    (v) =>
                      !!v ||
                      'Bitte lies und akzeptiere die Datenschutzbedingungen.',
                  ]"
                ></v-checkbox>
                <v-btn
                  :disabled="!valid"
                  @click="prepareMail()"
                  large
                  block
                  color="primary"
                >
                  Absenden
                </v-btn>
              </v-form>
              <v-layout v-if="isSent && visibility === true">
                <v-flex>
                  {{ feedbackMessage }}
                  <br /><br />
                  <v-btn @click="resetMail()" large block color="primary">
                    Weiteres Feedback schreiben
                  </v-btn>
                </v-flex>
              </v-layout>
            </template>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ["projects"],
  data() {
    return {
      videoHeight: 0,
      visibility: false,
      showFeedback: false,
      valid: false,
      message: "",
      subject: "",
      accept: false,
      feedbackMessage: "",
      isSent: false,
    };
  },
  mounted: function () {
    this.setBgImage(this.$route.name);
    window.removeEventListener("resize", this.resizeCarousel);
    window.addEventListener("resize", this.resizeCarousel);
    const interval = setInterval(() => {
      this.resizeCarousel();
      if (this.videoHeight !== 0) {
        clearInterval(interval);
      }
    }, 100);
  },
  methods: {
    setVisibility() {
      this.visibility = true;
      this.message = "";
      this.accept = false;
      this.subject = "Feedback zu: " + this.computedProject.title;
      this.isSent = false;
    },
    resetMail() {
      this.message = "";
      this.accept = false;
      this.isSent = false;
      this.feedbackMessage = "";
    },
    prepareMail() {
      const content = {
        message: this.message,
        subject: this.subject,
      };
      this.sendFeedback(content)
        .then((response) => {
          this.isSent = true;
          if (response.data === false) {
            this.feedbackMessage =
              "Ohhhh nooo. Das hat leider nicht geklappt. Bitte versuche es erneut, oder wende dich direkt an den/die angegebenen(n) Ansprechpartner*in.";
          } else {
            this.feedbackMessage = "Vielen Dank für dein Feedback!";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    resizeCarousel() {
      for (const item of document.querySelectorAll(
        "#carousel .v-carousel__item"
      )) {
        if (item.style.display !== "none") {
          this.videoHeight =
            item.children[0].children[0].getBoundingClientRect().height;
          return;
        }
      }
    },
  },
  computed: {
    computedProject() {
      return this.projects.find(
        (project) =>
          project.title.replace(/\s/g, "").split(".").join("") ===
          this.$route.params.id
      );
    },
  },
};
</script>

<style scoped>
.breakall {
  word-break: break-all;
}
</style>
