<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .über uns </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex my-5 body-1>
        Die popbastion.ulm ist eine Einrichtung zur Förderung der Rock- und
        Popmusikszene in Ulm und der Region. Die Einrichtung wurde 2002 ins
        Leben gerufen und wird getragen von der Kulturabteilung der Stadt Ulm
        und dem Stadtjugendring Ulm e.V.
        <br /><br />
        In Zusammenarbeit mit städtischen, regionalen und landesweiten Partnern
        aus Kultur, Jugendarbeit, Wirtschaft und Bildung initiiert die
        popbastion.ulm kreative Prozesse, vielfältige Projekte und die
        unterschiedlichsten Veranstaltungen.
        <br /><br />
        Das Angebot umfasst Beratung, Qualifizierung und Vernetzung innerhalb
        der Szene und darüber hinaus und reicht von der Vermittlung von Probe-
        und Auftrittsmöglichkeiten und Workshopangeboten bis hin zu Umsetzung
        von Projekten mit den unterschiedlichsten Partnern. Ein Schwerpunkt der
        Arbeit liegt zudem in der musik- und sozialpädagogischen Arbeit. So
        ermöglicht die popbastion.ulm insbesondere auch Menschen mit
        Migrationshintergrund und aus bildungsfernen Familien die Teilhabe am
        künstlerisch kulturellen Geschehen in der Gesellschaft.
        <br /><br />
        Durch dieses große Leistungsspektrum schafft die popbastion.ulm Anreize
        in der Szene, neue und eigene Projekte anzugehen bzw. bestehende
        weiterzuentwickeln. Unterstützt wird die Arbeit der popbastion.ulm von
        einem facettenreichen Expertennetzwerk, das adressatenorientiert
        Weiterbildungen anbieten, Talente fördern und Projekte unterstützen
        kann.
      </v-flex>
    </v-layout>

    <v-layout v-bind="binding">
      <v-flex py-3 xs12 sm6>
        <v-card color="transparent" flat>
          <v-card-title class="headline pl-0"> Unsere Mission </v-card-title>
          <v-card-text class="body-1 pl-0">
            Wir setzen uns zum Ziel, Menschen jeglichen Alters, Herkunft und
            Geschlechts in Ulm einen Zugang zur und Teilhabe an Popularmusik zu
            ermöglichen, bzw. diejenigen zu fördern, die sich im
            popmusikalischen Bereich weiterentwickeln möchten. Gleichzeitig
            stellt die popbastion.ulm eine Plattform dar, welche die lokale und
            subkulturelle Musikszene fördert.
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex py-3 xs12 sm6>
        <v-card color="transparent" flat>
          <v-card-title class="headline pl-0"> Unsere Werte </v-card-title>
          <v-card-text class="body-1 pl-0">
            „Einfach machen“ lautet unsere Devise: Es gilt, nicht zu viel über
            Etwas nachzudenken, sondern neue Ideen erst einmal auszuprobieren,
            bevor sie als unmöglich abgestempelt werden. Einen hohen Stellenwert
            haben für uns die Begriffe der Inklusion und der Chancengleichheit:
            Jeder ist bei uns willkommen, niemandem wird der Zugang zur
            Popularmusik verwehrt. Unsere Arbeit richtet sich nach dem Prinzip
            des Empowerments aus: Wir bieten jedem, der zu uns kommt, unsere
            Unterstützung an, mit dem Ziel, dass derjenige irgendwann auf
            eigenen Beinen stehen kann.
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex py-3 xs12 sm6>
        <v-card color="transparent" flat>
          <v-card-title class="headline px-0">
            Was uns auszeichnet...
          </v-card-title>
          <v-card-text class="body-1 pl-0">
            <ul>
              <li>
                sind unsere Erfahrungswerte: Bereits seit 2002 entwickeln wir
                Projekte unter Einbezug der subkulturellen und lokalen
                Bedürfnisse.
              </li>
              <li>
                ist unser kompetentes Dozententeam, welches uns schon seit
                vielen Jahren unterstützt und begleitet und genau weiß, worauf
                es bei einem Coaching ankommt.
              </li>
              <li>
                ist, dass wir versuchen jegliche Abläufe unbürokratisch und
                unkompliziert zu handhaben.
              </li>
              <li>
                ist unser Kontakt auf Augenhöhe mit all denjenigen, die zu uns
                kommen.
              </li>
              <li>
                ist unser kommunales, überregionales, bundesweites und
                internationales Netzwerk.
              </li>
              <li>ist unsere Identifikation mit der Musik- und Kulturszene.</li>
              <li>ist unsere Liebe zur Musik!</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- history -->
    <v-layout>
      <v-flex xs12 my-5>
        <v-card class="pb-5">
          <v-img class="odd" src=""></v-img>

          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >Historie</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-expansion-panel flat>
              <v-expansion-panel-content>
                <div slot="header">2018</div>
                <v-card>
                  <v-card-text>
                    Nachdem 2017 das bundesweite Projekt pop2go auslief, wurden
                    die Workshops im Jahr 2018 über andere Mittel unter dem
                    Namen offene.musikwerkstatt weitergeführt. Jeweils montags,
                    dienstags und mittwochs hatten Kinder und Jugendliche die
                    Möglichkeit im Proberaum popbastion Instrumente zu erlernen
                    und Songs einzustudieren.
                    <br /><br />
                    haus.besuch wurde weiter ausgebaut. Insgesamt 12 Jugendliche
                    durften über die Gänseblümchen- und Kinderstiftung Ulm/Donau
                    kostenlosen Musikunterricht in Anspruch nehmen.
                    <br /><br />
                    Innerhalb des Formats popschorle fanden vier Konzert im Club
                    Schilli statt.
                    <br /><br />
                    Der Ulmer Zirkel lief Ende 2018 aus. 2019 sollen die
                    Fördermöglichkeiten weiter geöffnet werden.
                    <br /><br />
                    Am 10. April veranstalteten das Roxy, die Kulturabteilung
                    der Stadt Ulm, die Wirtschaftsförderung Ulm/Neu-Ulm, die
                    Film Comission Region Ulm und die popbastion.ulm die Ausgabe
                    7x7 Kultur mit Gästen aus den Bereichen Medien, Fotografie,
                    Design, Softwareentwicklung und Musik.
                    <br /><br />
                    Im Mai fand zum ersten Mal die dialog.pop-Konferenz in
                    Alteglofsheim statt, ein Vernetzungstreffen, bei welchem
                    unterschiedliche Akteure der Popularmusikförderung sich
                    austauschten und neue Projektideen entwickelten.
                    <br /><br />
                    Die Idee des netzwerktreffens wurde aufrechterhalten. So
                    fand im Juni das zweite Treffen im Roxy mit einem Q&A mit
                    den Blackout Problems statt und im Dezember das dritte im
                    Cabaret Eden, bei welchem Stefanie Hock von der MFG (Medien-
                    und Filmgesellschaft Baden-Württemberg) über deren Förder-
                    und Beratungsmöglichkeiten informierte.
                    <br />
                    Es ist geplant, die netzwerktreffen im sechsmonatigen
                    Rhythmus zu veranstalten.
                    <br /><br />
                    Ein großes Highlight war das bereits vierte donau.pop.camp
                    im Juli, bei welchem zum ersten Mal die Länder Ungarn,
                    Bulgarien und Rumänien vertreten waren.
                    <br /><br />
                    Im Rahmen des „Pop Up Space 2018“ wurde über den
                    Arbeitskreis Kultur im August und September jeden Donnerstag
                    auf der Wilhelmsburg ein facettenreiches Programm angeboten,
                    von Literaturvorträgen bis hin zu Konzerten.
                    <br /><br />
                    Im September startete, zusammen mit der Jungen Ulmer Bühne
                    und Radio FreeFM, ein neues Kooperationsprojekt mit dem
                    Thema „Kulturelle Teilhabe“. Ziel des Projekts ist es, im
                    Mai 2019 ein Live-Hörspiel bei Radio FreeFM zu senden. Dafür
                    treffen sich SchülerInnen der 8. Klasse der
                    Albrecht-Berblinger Schule wöchentlich in der Jungen Ulmer
                    Bühne, erarbeiten gemeinsam ein Konzept und werden
                    zusätzlich mit den Aufgabenbereichen und den Räumlichkeiten
                    der popbastion.ulm und FreeFM vertraut gemacht.
                    <br /><br />
                    Im Oktober traten im Zuge des Jubiläums der Kinderstiftung,
                    welche unter anderem die offene.musikwerkstatt und den
                    Instrumentalunterricht unterstützt, Jugendliche auf und
                    gaben ihr Erlerntes zum Besten.
                    <br /><br />
                    Die go professional-Workshopreihe war 2018 so gut besucht,
                    wie noch nie zuvor. Zu den Workshop-Themen gehörten
                    Musikvideos, Konzertfotografie, Tontechnik und Booking.
                    <br /><br />
                    Alle Projektgruppen der popbastion.ulm sowie Bands, die noch
                    wenig Auftrittserfahrung hatten, präsentierten am 2.
                    Dezember im Gleis44 vor circa 100 Besuchern, was sie das
                    Jahr über einstudiert und geprobt hatten.
                    <br /><br />
                    Als Jahresabschluss fand am 23. Dezember das Heimspiel
                    statt, bei welchem rund 500 Besucher den Weg ins Roxy
                    fanden. Mit dabei waren KOSMO, Luke Noa, Van Holzen und
                    Roadstring Army.
                    <br /><br />
                    Zuletzt ging pünktlich zu Weihnachten, am 24. Dezember, die
                    neue Homepage der popbastion.ulm im neuen Design online.
                    <br /><br />
                    Der Proberaum popbastion war mit 200 Stunden pro Monat fast
                    vollständig ausgebucht.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2017</div>
                <v-card>
                  <v-card-text>
                    Mit vier Maßnahmen in vier Bündnissen, 59 Teilnehmern und
                    den Kooperationspartnern TenDance, Adalbert-Stifter Schule,
                    Realschule Blaustein, Musikschule o.ton in Ehingen,
                    Jugendzentrum E.GO in Ehingen und der Valckenburgschule
                    schließen wir das vorerst letzte von fünf Jahren pop to go
                    ab.
                    <br /><br />
                    Mit dem vorläufigen Ende von pop to go haben wir eine Reihe
                    erfolgreicher Projekte im Rahmen offener Musikwerkstätte
                    eine neue Plattform geben können. Wöchentlich finden sich in
                    der popbastion.ulm zu verschiedenen Tagen Kinder und
                    Jugendliche mit Coaches zusammen, um gemeinsam Musik zu
                    machen.
                    <br /><br />
                    Mit haus.besuch starten wir ein neues Musikprojekt für Alle
                    und vor Allem diejenigen, denen es nicht möglicht ist, zu
                    einer unseren fast täglich stattfindenden, offenen
                    Musikwerkstätten zu gelangen. Zwei Dozenten gehen direkt in
                    die Einrichtung und machen dort zusammen mit den Menschen
                    Musik – egal auf welche Art und Weise – das waren Orte wie:
                    Lebenshilfe Jungingen, Ausbildungszentrum RAZ, BvE,
                    Sägefeldschule, Bodelschwingh-Kindergarten,
                    Hans-Lebrecht-Schule.
                    <br /><br />
                    Mit der popschorle starteten wir in ein neues Konzept, das
                    vor Allem jungen Bands aus Stadt und Region eine Plattform
                    geben soll und es gleichzeitig schafft diese miteinander zu
                    vernetzen, sich auszutauschen und sie voran zu bringen.
                    <br /><br />
                    Gleich zweimal waren wir an 7×7 beteiligt. Das mittlerweile
                    stadtbekannte Format ging 2017 in eine neue Runde und die
                    neue Rubrik: Natur und Technik. Die Kooperationspartner
                    ROXY, Stadtjugendring Ulm, Kulturabteilung Stadt Ulm und
                    Bildungsnetzwerk Ulm / Neu-Ulm luden sieben Ulmer
                    Bildungsanbieter und -macher ein, um ihre jeweiligen
                    Tätigkeiten vor Publikum zu präsentieren.
                    <br /><br />
                    Auch in 2017 durften wir mit Mitteln unseres Partners
                    RegioNET, der Popakademie Baden-Württemberg wieder zwölf
                    Ulmer Bands finanziell für Auftritte über 50km außerhalb der
                    Stadtgrenzen unter die Arme greifen.
                    <br /><br />
                    Im Oktober starteten wir eine Umfrage um die Interessen der
                    Ulmer Musikszene abzufragen. Der Abend erfreute sich einer
                    sehr positiven Resonanz und war seitens der
                    Kooperationspartner ein voller Erfolg. Über 100 Teilnehmende
                    fanden ihren Weg in den Club Schilli.
                    <br /><br />
                    Unser Projektproberaum erfreute sich nach wie vor breitem
                    Interesse und war mit durchschnittlich 160 Stunden im Monat
                    weitgehend ausgebucht
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2016</div>
                <v-card>
                  <v-card-text>
                    Insgesamt können wir in 2016 auf fünf Bündnisse mit elf
                    Maßnahmen und 229 Teilnehmern zurückblicken, die vorwiegend
                    aus sozial benachteiligten Jugendlichen, sowie jungen
                    Geflüchteten aus Ulm und Neu-Ulm bestehen und liegt sowohl
                    bundes- als auch landesweit weit über dem Durchschnitt.
                    <br /><br />
                    Mit einigen Neuerungen geht das donau.pop.camp in die dritte
                    Runde und wird ein voller Erfolg. „Nine days that de nitely
                    changed our life!“ postet die serbische Band Iskaz am
                    nächsten Tag auf ihrem Facebook Profil, „The opportunity to
                    meet people, to exchange experiences, learn and accept
                    differences, gain new friends and we dare say – family!“
                    <br /><br />
                    Nach 2015 kommen die Gewinner des Bandförderpreis PLAY LIVE
                    mit Luke Noa & The Basement Beats erneut aus Ulm.
                    <br /><br />
                    Zum ersten Mal konnten wir insgesamt 12 Ulmer Bands und
                    Musikern eine zumindest kleine finanzielle Unterstützung von
                    je 500€ für Konzerte außerhalb des Ulmer Raums zur Verfügung
                    stellen, um etwaige Kosten zu decken.
                    <br /><br />
                    In Kooperation mit dem Obstwiesenfestival in Dornstadt
                    durften wir wieder die jeweiligen Opener der beiden
                    Festivaltage präsentieren und mit Ulmer Bands besetzen.
                    <br /><br />
                    In Zusammenarbeit mit dem VPBy fand wieder eine fünfteilige
                    Workshopreihe zu Themen wie Mikrofonierung, Homerecording,
                    Booking oder Releaseplanung statt. Unterstützt wurden wir
                    hierbei von der Wirtschaftsförderung und dem
                    Stadtentwicklungsverband Ulm/Neu-Ulm.
                    <br /><br />
                    Krönender Jahresabschluss war das zum zweiten Mal
                    ausgetragene Heimspiel am 23.12., bei dem wir in Kooperation
                    mit dem Roxy jeden einzelnen Schritt mit allen vier
                    teilnehmenden Bands, seit Sommer gemeinsam und absolut
                    transparent geplant und durchgeführt haben.
                    <br /><br />
                    Der Projektproberaum hält seine Taktung mit 140 Stunden im
                    Monat weiterhin hoch.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2015</div>
                <v-card>
                  <v-card-text>
                    PLAY LIVE Kesselkongress findet in Ulm statt. Ulmer Band VAN
                    HOLZEN gewinnt PLAY LIVE
                    <br /><br />
                    Alle Bands des donau.pop.camp 2014 treffen sich im Mai 2015
                    für ein Revival-Wochenende in Serbien
                    <br /><br />
                    Unsere Umsetzung des bundesweiten POP TO GO Projekts wird
                    auf dem diesjährigen POP SUMMIT im November in Berlin als
                    „Best-Practice“ Beispiel vorgestellt.
                    <br /><br />
                    POP TO GO zusätzlich im ländlichen Raum vertreten, außerdem
                    erste Kooperationen in Neu-Ulm.
                    <br /><br />
                    Hier in Ulm und Neu-Ulm lief das Projekt mit 9 Maßnahmen und
                    380 TeilnehmerInnen, die vorwiegend aus sozial
                    benachteiligten Jugendlichen, sowie jungen Geflüchteten
                    bestehen mehr als gut.
                    <br /><br />
                    Erstmals durften wir in Kooperation mit dem
                    Obstwiesenfestival in Dornstadt die jeweiligen Opener der
                    beiden Festivaltage präsentieren und mit Ulmer Bands
                    besetzen.
                    <br /><br />
                    Der Ulmer Gemeinderat beschließt auf Antrag der
                    popbastion.ulm und der Kulturabteilung den Zuschuss für ein
                    weiteres donau.pop.camp, das nach zwei erfolgreichen
                    Durchführungen 2012 und 2014 nun 2016 erneut im Rahmen des
                    Internationalen Donaufests stattfinden darf.
                    <br /><br />
                    Der Projektproberaum an der Kradhalle ist mit ca. 140
                    Stunden / Monat nach wie vor sehr hoch frequentiert und sehr
                    gut ausgelastet.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2014</div>
                <v-card>
                  <v-card-text>
                    PLAY LIVE Kesselkongress findet in Ulm statt.
                    <br /><br />
                    Neuauflage des donau.pop.camps mit Bands aus der Ulmer
                    Region, Mazedonien und Serbien.
                    <br /><br />
                    POP TO GO Angebote werden ausgedehnt, die täglichen Angebot
                    bleiben bestehen, die Tanzschule „StepToTenDance“ steigt mit
                    ein, „Vom Klassenzimmer auf die Bühne“ feiert Premiere.
                    <br /><br />
                    Beginn der Kooperation mit der Musikschule Gregorianum
                    Laupheim und dem Projekt „HIP TO ROCK“.
                    <br /><br />
                    Die Idee „Ulmer Zirkel“ wird initiiert, „Bandpusher“ wird
                    eingestampft.
                    <br /><br />
                    Erste Kooperation mit „GO PROFESSIONAL“ Workshopreihe und
                    dem Rock.Büro.Süd.
                    <br /><br />
                    „Inklusion in der Bastion“ feiert Premiere.
                    <br /><br />
                    Die „HEIMSPIEL“ Reihe für Bands aus der Region Ulm wird ins
                    Leben gerufen.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2013</div>
                <v-card>
                  <v-card-text>
                    Beginn der Zusammenarbeit mit dem Bundesverband Popularmusik
                    e.V.
                    <br /><br />
                    Erste Maßnahmen des „Kultur macht Stark“ Programms „POP TO
                    GO – unterwegs im Leben“ werden in der popbastion.ulm
                    durchgeführt. Ein täglich stattfindendes Programm in der
                    popbastion.ulm wird ins Leben gerufen.
                    <br /><br />
                    In der Magirusstraße wächst ein neues Proberaumzentrum
                    heran.
                    <br /><br />
                    Erster Workshop in Kooperation mit „OHURA –
                    MädchenMusikFörderprojekt Bayern“.
                    <br /><br />
                    Vertiefung der Inklusionsarbeit; die Band benennt sich in
                    „care or not“ um.
                    <br /><br />
                    Erste gemeinsames „Kultur in der Donaubastion“ im Rahmen der
                    Ulmer Kulturnacht.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2012</div>
                <v-card>
                  <v-card-text>
                    Mit der Band „Don’t call it Schnitzel“ Einstieg in die
                    Inklusionsarbeit.
                    <br /><br />
                    Premiere des „donau.pop.camp“ im Rahmen des Donaufests.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2011</div>
                <v-card>
                  <v-card-text>
                    Die Landesarbeitsgemeinschaft Pop Baden-Württemberg wird aus
                    der Taufe gehoben.“Band sucht Bleibe“ – Plakat-, Flyer- und
                    Presseaktionen gegen die Misere der Raumnot.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2010</div>
                <v-card>
                  <v-card-text>
                    Im Dezember findet der erste monatliche Sprechtag des
                    Kompetenzzentrums Kultur- und Kreativwirtschaft statt.Die
                    Reihe „YoungStars“ wird in Kooperation mit JAM gestartet.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2009</div>
                <v-card>
                  <v-card-text>
                    Zur Weiterentwicklung der popbastion.ulm stellt der
                    Fachbereich Kultur, Bildung, Sport und Freizeit der Stadt
                    Ulm einen Betrag von 20.000 € für eine halbe Personalstelle
                    zur Verfügung.
                    <br /><br />
                    „Was geht mich Eure Geschichte an?“ – Interkulturelle
                    Projektarbeit mit DZOK startet.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2008</div>
                <v-card>
                  <v-card-text>
                    Die Räume in der ehemaligen Kradhalle werden
                    bezogen.Förderpreis „Meine Schule rockt“ geht an zwei
                    Schulprojekte der popbastion.ulm. Erstes „Donaubeben“
                    Benefiz-Open-Air findet am 14.6.08 in der Donaubastion
                    statt.Vom Europäischen Sozialfonds geförderte Schulprojekte
                    laufen an (3 Jahre).„Rocken statt Ritzen“ in Kooperation mit
                    der Uni Ulm/Kinder- und Jugendpsychiatrie.
                    <br /><br />
                    Die Wieland-Werke AG steigen in das Sponsoring ein.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2007</div>
                <v-card>
                  <v-card-text>
                    Die Bagger rücken an, das Gebäude Schillerstr. 1/10 muss
                    komplett geräumt werden.„Play Live“ / Landesweiter
                    Nachwuchswettbewerb / Auftakt in Ulm.„Studio Frizz“ wird als
                    Aufnahmestudio aufgelöst.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2006</div>
                <v-card>
                  <v-card-text>
                    Für die popbastion.ulm soll die Kradhalle saniert werden
                    (gemeinsame Nutzung mit Kradhalle e.V.). „Mein Block rockt!“
                    startet in den Sozialräumen. Die popbastion.ulm erarbeitet
                    ein Konzept für die landesweite Lehrerfortbildung „Popmusik
                    an Schulen“.Crossover-Musical „Rock mich Amadeus“
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2005</div>
                <v-card>
                  <v-card-text>
                    „Popmusik an Schulen“ in Kooperation mit dem Lehrerseminar
                    in Laupheim läuft an.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2004</div>
                <v-card>
                  <v-card-text>
                    Das Projekt „School of Rock“ findet zum ersten Mal statt /
                    23 Schulen, 405 Schüler, 28 Coaches, 1.000 Besucher im Roxy.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2003</div>
                <v-card>
                  <v-card-text>
                    Ab August 2003 proben in der popbastion.ulm (Schillerstraße
                    1/10) 4 Bands / Seminare und Workshops finden statt / 2
                    Studios für Profi-Musiker werden eingerichtet.Ulm wird mit
                    dem Anschluss an das RegioNet der Popakademie
                    Baden-Württemberg zum Vorreiter für Rock und Pop im
                    Südwesten. Vernetzt mit weiteren 4 Städten im Ländle
                    (Stuttgart, Mannheim, Karlsruhe, Freiburg) wird die
                    popbastion.ulm aus der Taufe gehoben – ein
                    Kooperationsprojekt zwischen der Stadt Ulm, der
                    Kulturabteilung und dem Stadtjugendring Ulm e.V.1. Szene
                    Star / Gewinner: Sepcys, 2. Platz Semtex!
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <div slot="header">2002</div>
                <v-card>
                  <v-card-text>
                    Vertreter aus der Rock- und Popszene erstellen ein Konzept
                    für die Nachwuchsförderung Rock und Pop in Ulm.Der
                    Fachbereich Kultur, Bildung, Sport und Freizeit der Stadt
                    Ulm beschließt einstimmig die Konzeption „Nachwuchsförderung
                    Rock und Pop“ sowie das Nutzungskonz ept für das Gebäude
                    Schillerstraße 1/10 (ehemalige andere Baustelle, heute
                    Akademie) in der Oberen Donaubastion zur Unterbringung der
                    popbastion.ulm und kontiki.
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["team"],
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      this.scrollToElement(this.$route.params.id);
    }
  },
  computed: {
    binding() {
      const binding = {};
      if (this.$vuetify.breakpoint.mdAndUp) {
        binding.row = true;
      } else {
        binding.column = true;
      }
      return binding;
    },
    bindingHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "200px";
      } else {
        return "350px";
      }
    },
    bindingTeam() {
      const binding = {};
      if (this.team.length < 3) {
        binding.sm6 = true;
        binding.md6 = true;
        binding.lg6 = true;
      } else {
        binding.sm4 = true;
        binding.md4 = true;
        binding.lg4 = true;
      }
      return binding;
    },
  },
};
</script>

<style scoped>
li {
  margin-bottom: 20px;
}
</style>
