<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .was wir machen </v-flex>
    </v-layout>

    <!-- projekte -->

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap>
        <v-flex
          @click="
            $router.push(
              'projekte/' + project.title.replace(/\s/g, '').split('.').join('')
            )
          "
          v-for="(project, i) in projects"
          :key="i"
          xs12
          sm6
          md4
          mb-4
        >
          <v-card flat tile>
            <v-img :src="'/img/projekte/' + project.titleImage" height="150px">
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  {{ project.title }}
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: ["projects"],
  data() {
    return {
      videoHeight: 0,
      visibilities: [],
      showFeedback: false,
      valid: false,
      message: "",
      subject: "",
      accept: false,
      feedbackMessage: "",
      isSent: false,
    };
  },
  created: function () {
    this.visibilities = [];
    for (let i = 0; i < this.projects.length; i++) {
      this.visibilities.push(false);
    }
  },
  mounted: function () {
    this.setBgImage(this.$route.name);
    this.scrollToElement(this.$route.params.id);
    window.removeEventListener("resize", this.resizeCarousel);
    window.addEventListener("resize", this.resizeCarousel);
    const interval = setInterval(() => {
      this.resizeCarousel();
      if (this.videoHeight !== 0) {
        clearInterval(interval);
      }
    }, 100);
  },
  methods: {
    setVisibilities(index) {
      for (let i = 0; i < this.visibilities.length; i++) {
        this.$set(this.visibilities, i, false);
      }
      this.$set(this.visibilities, index, true);
      this.message = "";
      this.accept = false;
      this.subject = "Feedback zu: " + this.projects[index].title;
      this.isSent = false;
    },
    resetMail() {
      this.message = "";
      this.accept = false;
      this.isSent = false;
      this.feedbackMessage = "";
    },
    prepareMail() {
      const content = {
        message: this.message,
        subject: this.subject,
      };
      this.sendFeedback(content)
        .then((response) => {
          this.isSent = true;
          if (response.data === false) {
            this.feedbackMessage =
              "Ohhhh nooo. Das hat leider nicht geklappt. Bitte versuche es erneut, oder wende dich direkt an den/die angegebenen(n) Ansprechpartner*in.";
          } else {
            this.feedbackMessage = "Vielen Dank für dein Feedback!";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    resizeCarousel() {
      for (const item of document.querySelectorAll(
        "#carousel .v-carousel__item"
      )) {
        if (item.style.display !== "none") {
          this.videoHeight =
            item.children[0].children[0].getBoundingClientRect().height;
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.breakall {
  word-break: break-all;
}
</style>
