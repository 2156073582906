<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .termine </v-flex>
    </v-layout>

    <!-- events -->

    <v-layout column>
      <template v-if="fbEvents.length > 0">
        <v-flex
          :id="'e' + event.id"
          v-for="(event, i) in fbEvents"
          :key="i"
          xs12
          my-5
        >
          <v-card>
            <v-img class="odd" :src="event.cover.source"></v-img>
            <v-card-title
              :class="{
                'display-1': true,
                'font-weight-light': true,
                'mt-4': true,
                'px-0': $vuetify.breakpoint.xsAndDown,
                'px-5': $vuetify.breakpoint.smAndUp,
              }"
              >{{ event.name }}</v-card-title
            >
            <v-card-text
              :class="{
                'body-1': true,
                'px-0': $vuetify.breakpoint.xsAndDown,
                'px-5': $vuetify.breakpoint.smAndUp,
              }"
            >
              <div class="font-weight-light font-italic">
                Wann: {{ new Date(event.start_time).toLocaleString() }}
              </div>
              <div class="font-weight-light font-italic">
                Wo: {{ event.place ? event.place.name : "tba" }}
              </div>
              <br />
              <p class="fb-text">{{ event.description }}</p>
            </v-card-text>

            <v-card-actions class="px-5 pb-5">
              <v-spacer></v-spacer>
              <v-btn
                icon
                :href="'https://fb.com/events/' + event.id"
                target="_blank"
              >
                <img height="24px" src="../../static/facebook_w.png" />
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </template>
      <template v-else>
        <div class="display-1 font-weight-light">
          Aktuelle Termine findet ihr <a target="_blank" href="https://www.facebook.com/popbastion/events/">HIER</a>.
        </div>
      </template>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["fbEvents"],
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      this.scrollToElement(this.$route.params.id);
    }
  },
};
</script>

<style scoped>
.fb-text {
  white-space: pre-wrap;
}
</style>
