import "@babel/polyfill";
import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/mc";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const VueScrollTo = require("vue-scrollto");
import Globals from "./plugins/globals";

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(Globals);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
