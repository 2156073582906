var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"display-4":"","font-weight-thin":"","my-5":"","pt-5":""}},[_vm._v(" .termine ")])],1),_c('v-layout',{attrs:{"column":""}},[(_vm.fbEvents.length > 0)?_vm._l((_vm.fbEvents),function(event,i){return _c('v-flex',{key:i,attrs:{"id":'e' + event.id,"xs12":"","my-5":""}},[_c('v-card',[_c('v-img',{staticClass:"odd",attrs:{"src":event.cover.source}}),_c('v-card-title',{class:{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': _vm.$vuetify.breakpoint.xsAndDown,
              'px-5': _vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(_vm._s(event.name))]),_c('v-card-text',{class:{
              'body-1': true,
              'px-0': _vm.$vuetify.breakpoint.xsAndDown,
              'px-5': _vm.$vuetify.breakpoint.smAndUp,
            }},[_c('div',{staticClass:"font-weight-light font-italic"},[_vm._v(" Wann: "+_vm._s(new Date(event.start_time).toLocaleString())+" ")]),_c('div',{staticClass:"font-weight-light font-italic"},[_vm._v(" Wo: "+_vm._s(event.place ? event.place.name : "tba")+" ")]),_c('br'),_c('p',{staticClass:"fb-text"},[_vm._v(_vm._s(event.description))])]),_c('v-card-actions',{staticClass:"px-5 pb-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","href":'https://fb.com/events/' + event.id,"target":"_blank"}},[_c('img',{attrs:{"height":"24px","src":require("../../static/facebook_w.png")}})])],1)],1)],1)}):[_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v(" Aktuelle Termine findet ihr "),_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/popbastion/events/"}},[_vm._v("HIER")]),_vm._v(". ")])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }