<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .impressionen </v-flex>
    </v-layout>

    <!-- impressions -->

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap v-if="fbPics.length > 0">
        <v-flex v-for="(pic, i) in visibleFbPics" :key="i" xs6 sm3 md3 lg2>
          <v-card flat tile>
            <v-img
              @click="showImage(i)"
              class="clickable"
              :src="pic.source"
              :id="'id' + pic.id"
              height="150px"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-layout>
      <v-flex text-xs-center xs12 ma-5>
        <v-btn large @click="maxPics += 24"> Mehr anzeigen </v-btn>
      </v-flex>
    </v-layout>

    <v-dialog v-model="showLightbox" flat>
      <div
        class="lightbox-image"
        :style="'background-image:url(' + computedActiveImage + ')'"
      >
        <v-btn
          color="grey darken-3"
          class="close next"
          large
          icon
          @click="showLightbox = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn
          @click="activeImage--"
          :disabled="activeImage <= 0"
          large
          color="grey darken-3"
          class="nav prev"
          icon
        >
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-btn
          @click="activeImage++"
          :disabled="activeImage >= visibleFbPics.length - 1"
          large
          color="grey darken-3"
          class="nav next"
          icon
        >
          <v-icon>chevron_right</v-icon>
        </v-btn>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showLightbox: false,
      activeImage: 0,
      maxPics: 18,
    };
  },
  props: ["fbPics"],
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      const index = this.fbPics.findIndex((e) => {
        return e.id == this.$route.params.id.replace("id", "");
      });
      this.showImage(index);
      this.maxPics = index + 1;
      setTimeout(() => {
        this.scrollToElement(this.$route.params.id);
      }, 1000);
    }
  },
  methods: {
    showImage: function (i) {
      this.activeImage = i;
      this.showLightbox = true;
      this.$router.push("/impressionen/id" + this.fbPics[i].id);
    },
  },
  computed: {
    visibleFbPics: function () {
      const clone = this.fbPics.slice();
      return clone.splice(0, this.maxPics);
    },
    computedActiveImage: function () {
      if (this.visibleFbPics.length > 0) {
        return this.fbPics[this.activeImage].source;
      } else {
        return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lightbox-image {
  display: block;
  position: absolute;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  left: 0px;
  top: 0px;
  margin: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0px;
}

@media only screen and (max-width: 700px) {
  .lightbox-image {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    margin-left: 0px;
  }

  .prev {
    left: 0px !important;
  }

  .next {
    right: 0px !important;
  }
}

.close.next {
  position: absolute;
  top: 20px;
}

.nav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}
</style>
