<template>
  <v-container>
    <!-- news -->

    <v-layout>
      <v-flex display-3 font-weight-light my-5 pt-3> .neues </v-flex>
    </v-layout>

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap>
        <v-flex
          v-for="(newsEntry, i) in news"
          :key="i"
          @click="$router.push('neues/id' + i)"
          xs12
          sm6
          md4
          mb-4
        >
          <v-card flat tile>
            <v-img :src="'/img/neues/' + newsEntry.titleImage" height="150px">
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  {{ newsEntry.title }}
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- projekte -->

    <v-layout>
      <v-flex display-3 font-weight-light my-5 pt-3> .was wir machen </v-flex>
    </v-layout>

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap>
        <v-flex @click="$router.push('projekte')" xs12 sm6 md4 mb-4>
          <v-card flat tile>
            <v-img :src="'/bgimg/projekte.jpg'" height="150px">
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  Zur Übersicht
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
        <v-flex
          @click="
            $router.push(
              'projekte/' + project.title.replace(/\s/g, '').split('.').join('')
            )
          "
          v-for="(project, i) in projects.slice(0, 5)"
          :key="i"
          xs12
          sm6
          md4
          mb-4
        >
          <v-card flat tile>
            <v-img :src="'/img/projekte/' + project.titleImage" height="150px">
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  {{ project.title }}
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- impressions -->
    <!--
    <v-container grid-list-lg pa-0 ma-0>
      <v-layout>
        <v-flex display-3 font-weight-light my-5 pt-3 id="impressionen">
          .impressionen
        </v-flex>
      </v-layout>

      <v-layout v-if="fbPics.length > 0" row wrap>
        <v-flex v-for="i in 9" :key="i" xs6 sm4 md3>
          <v-card flat tile>
            <v-img
              @click="$router.push('/impressionen/id' + fbPics[i - 1].id)"
              :src="fbPics[i - 1].source"
              height="150px"
              class="clickable"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs12 text-xs-center>
          <v-btn @click="$router.push('/impressionen')" primary large
            >Mehr ansehen</v-btn
          >
        </v-flex>
      </v-layout>
    </v-container>
    -->

    <!-- events -->

    <v-container class="px-0">
      <v-layout>
        <v-flex display-3 font-weight-light my-5 pt-3> .termine </v-flex>
      </v-layout>

      <v-layout column>
        <template v-if="fbEvents.length > 0">
          <v-flex v-for="(event, i) in fbEvents" :key="i" xs4 my-3>
            <v-card class="clickable">
              <v-card-title
                @click="$router.push('/termine/e' + event.id)"
                class="display-1 font-weight-light mt-1 pa-3"
                >{{ event.name }}</v-card-title
              >
              <v-card-text class="body-1 pl-3 pe-1 pt-0">
                <div
                  @click="$router.push('/events/id' + event.id)"
                  class="font-weight-light font-italic"
                >
                  Wann: {{ new Date(event.start_time).toLocaleString() }}
                </div>
                <div
                  @click="$router.push('/events/id' + event.id)"
                  class="font-weight-light font-italic"
                >
                  Wo: {{ event.place ? event.place.name : "tba" }}
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </template>
        <template v-else>
          <div class="display-1 font-weight-light">
            Aktuelle Termine findet ihr <a target="_blank" href="https://www.facebook.com/popbastion/events/">HIER</a>.
          </div>
        </template>
      </v-layout>
    </v-container>

    <!-- spotify -->

    <v-container class="px-0">
      <v-layout>
        <v-flex display-3 font-weight-light my-5 pt-3> .musik </v-flex>
      </v-layout>

      <v-layout column>
        <v-flex>
          <div class="embed-container">
            <iframe
              :src="playlist"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["fbEvents", "fbPics", "news", "projects", "playlist"],
  mounted: function () {
    this.setBgImage("home");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
