var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"xs12":"","id":_vm.computedProject.title.replace(/\s/g, '').split('.').join(''),"my-5":""}},[_c('v-card',{staticClass:"pb-5"},[_c('v-img',{staticClass:"odd",attrs:{"src":'/img/projekte/' + _vm.computedProject.titleImage}}),_c('v-card-title',{class:{
            breakall: true,
            'display-1': true,
            'font-weight-light': true,
            'mt-4': true,
            'px-0': _vm.$vuetify.breakpoint.xsAndDown,
            'px-5': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(_vm._s(_vm.computedProject.title))]),_c('v-card-text',{class:{
            'body-1': true,
            'px-0': _vm.$vuetify.breakpoint.xsAndDown,
            'px-5': _vm.$vuetify.breakpoint.smAndUp,
          }},[(_vm.computedProject.contact)?_c('div',{staticClass:"font-weight-light font-italic"},[_vm._v(" Ansprechpartner*in: "),_c('a',{on:{"click":function($event){return _vm.$router.push('/ueber-uns/team')}}},[_vm._v(_vm._s(_vm.computedProject.contact.name))]),_vm._v(" / "+_vm._s(_vm.computedProject.contact.email)+" ")]):_vm._e(),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s(_vm.computedProject.text)}}),_c('br'),(_vm.computedProject.images && _vm.computedProject.images.length > 0)?_c('v-carousel',{staticClass:"mt-3 mb-5"},_vm._l((_vm.computedProject.images),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":'/img/projekte/' + item}})}),1):_vm._e(),(_vm.computedProject.videos && _vm.computedProject.videos.length > 0)?_c('v-carousel',{staticClass:"mb-5",style:('height: ' + _vm.videoHeight + 'px !important'),attrs:{"hide-delimiters":"","id":"carousel","cycle":false}},_vm._l((_vm.computedProject.videos),function(item,i){return _c('v-carousel-item',{key:i},[_c('div',{staticClass:"responsive-video"},[_c('iframe',{attrs:{"width":"100%","src":'https://www.youtube-nocookie.com/embed/' +
                    item.link +
                    '?rel=0&showinfo=0',"frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}})])])}),1):_vm._e(),(
              _vm.computedProject.partners && _vm.computedProject.partners.length > 0
            )?_c('v-container',{attrs:{"grid-list-lg":"","pa-0":"","ma-0":""}},[_c('div',{staticClass:"subheading mb-3"},[_vm._v("Projekt-Partner:")]),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.computedProject.partners),function(partner,i){return _c('v-flex',{key:i,attrs:{"xs6":"","sm3":"","md3":"","lg2":""}},[_c('a',{attrs:{"target":"_blank","href":partner.link}},[_c('v-card',{staticStyle:{"padding":"15px"},attrs:{"flat":"","tile":"","color":"white"}},[_c('div',{staticClass:"partner-container",style:('background-image:url(/img/partner/' + partner.logo)})])],1)])}),1)],1):_vm._e(),(_vm.computedProject.feedback === 'true')?[_c('br'),_c('br'),(_vm.visibility === false)?_c('v-btn',{staticClass:"mx-0",attrs:{"outline":""},on:{"click":function($event){return _vm.setVisibility()}}},[_vm._v(" Feedback abgeben ")]):_vm._e(),(_vm.visibility === true && !_vm.isSent)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"box":"","label":"Dein Feedback","rules":[
                  function (v) { return !!v || 'Wolltest du uns nicht etwas Mitteilen?'; } ],"hint":"Falls du eine Antwort auf dein Feedback wünschst, denke bitte auch an deine Kontaktdaten."},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-checkbox',{attrs:{"color":"primary","label":"Ich habe die Datenschutz-Erklärung gelesen und akzeptiere diese.","rules":[
                  function (v) { return !!v ||
                    'Bitte lies und akzeptiere die Datenschutzbedingungen.'; } ]},model:{value:(_vm.accept),callback:function ($$v) {_vm.accept=$$v},expression:"accept"}}),_c('v-btn',{attrs:{"disabled":!_vm.valid,"large":"","block":"","color":"primary"},on:{"click":function($event){return _vm.prepareMail()}}},[_vm._v(" Absenden ")])],1):_vm._e(),(_vm.isSent && _vm.visibility === true)?_c('v-layout',[_c('v-flex',[_vm._v(" "+_vm._s(_vm.feedbackMessage)+" "),_c('br'),_c('br'),_c('v-btn',{attrs:{"large":"","block":"","color":"primary"},on:{"click":function($event){return _vm.resetMail()}}},[_vm._v(" Weiteres Feedback schreiben ")])],1)],1):_vm._e()]:_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }