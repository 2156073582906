<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .links </v-flex>
    </v-layout>

    <!-- projekte -->

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap>
        <v-flex v-for="(link, i) in links" :key="i" xs12 mb-4>
          <a :href="link.url" target="_blank">
            <v-card class="pa-4" flat tile>
              <div class="">
                <div class="headline">
                  {{ link.title }}
                </div>
                <div class="font-weight-light headline">
                  {{ link.description }}
                </div>
              </div>
            </v-card>
          </a>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: ["links"],
  data() {
    return {};
  },
  created: function () {},
  mounted: function () {
    this.setBgImage(this.$route.name);
  },
  methods: {},
};
</script>

<style scoped>
.breakall {
  word-break: break-all;
}
</style>
