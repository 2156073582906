var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"display-4":"","font-weight-thin":"","my-5":"","pt-5":""}},[_vm._v(" .neues ")])],1),_c('v-layout',{attrs:{"column":""}},_vm._l((_vm.news),function(newsEntry,i){return _c('v-flex',{key:i,attrs:{"id":'id' + i,"xs12":"","my-5":""}},[_c('v-card',[(newsEntry.titleImage)?_c('v-img',{staticClass:"odd",attrs:{"src":'/img/neues/' + newsEntry.titleImage}}):_vm._e(),_c('v-card-title',{class:{
            'display-1': true,
            'font-weight-light': true,
            'mt-4': true,
            'px-0': _vm.$vuetify.breakpoint.xsAndDown,
            'px-5': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(_vm._s(newsEntry.title))]),_c('v-card-text',{class:{
            'body-1': true,
            'px-0': _vm.$vuetify.breakpoint.xsAndDown,
            'px-5': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',{domProps:{"innerHTML":_vm._s(newsEntry.text)}})])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }